import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/caseShare',
    name: 'caseShare',
    component: () => import('../views/caseShare.vue')
  },
  {
    path: '/academicBackground',
    name: 'academicBackground',
    component: () => import('../views/academicBackground.vue')
  },
  {
    path: '/capabilityModel',
    name: 'capabilityModel',
    component: () => import('../views/capabilityModel.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/products.vue')
  },
  {
    path: '/researchTrends',
    name: 'researchTrends',
    component: () => import('../views/researchTrends.vue')
  },
  {
    path: '/servicePhilosophy',
    name: 'servicePhilosophy',
    component: () => import('../views/servicePhilosophy.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue')
  },
  {
    path: '/detailss',
    name: 'detailss',
    component: () => import('../views/detailss.vue')
  },
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
 next()
})
export default router
