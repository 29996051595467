<template>
  <div id="app">
    <navlist></navlist>
    <router-view/>
    <bottom></bottom>
  </div>
</template>
<script>
import navlist from './components/navlist'
import bottom from './components/bottom'
import Bottom from './components/bottom.vue'
export default {
  components: {
    navlist,bottom
  },
}
</script>
<style lang="scss">
a{
  text-decoration: none;
  color: #000;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top:80px;
  box-sizing: border-box;
  position: relative;
}
html,body{
  margin: 0 ;
  padding: 0;
  box-sizing: border-box;
  background: #F5F5F5;
  height: 100%;
  letter-spacing:1px;
    min-width: 1200PX; /* no */
}
#app{
  height: 100%;
}
*{
  box-sizing: border-box;
}
p{
  margin: 0;
  padding: 0;
}
</style>
