<template>
  <div class="bottom">
        <div>© univteam.com 2011 - 2031</div><div>天才在线（北京）科技有限公司  </div><div @click="toBeian">京ICP备12024124号-9</div>
  </div>
</template>

<script>
export default {
  name: 'navlist',
  data() {
      return {
        
      };
    },
    methods: {
      toBeian(){
          window.location.href = "https://beian.miit.gov.cn"
      }
    }
}
</script>
<style lang="scss" scoped>
.bottom{
    display: flex;
    margin-top: 1px;
    align-items: center;
    justify-content: center;
    height: 66px;
    background: #fff;
    box-shadow: 0 -2px 0 rgba(51,51,51,.1);
    font-size: 14px;
    color: #666666;
    div{
        text-align: left;
        padding-left: 50px;
        width: 300px;
        height: 20px;
        line-height: 20px;
    }
    div:nth-child(1){
        border-right:1px solid #999999;
        padding-right: 50px;
        text-align: right;
        width: 350px;
    }
        div:nth-child(2){
        border-right:1px solid #999999;
        text-align: right;
        width: 320px;
        padding-right: 50px;
    }
    div:nth-child(3){
        cursor: pointer;
    }
}
</style>
