<template>
  <div class="navlist">
        <img src="../assets/img/logo.png" alt="">
        <div v-for="(item,index) in list" :key="index" @click="getActive(index,item.path)" :class="{'active':active==index}">{{item.name}}</div>
  </div>
</template>

<script>
export default {
  name: 'navlist',
  data() {
      return {
        active:sessionStorage.getItem('active')||0,
        list:[{name:'首页',path:'/'},{name:'服务理念',path:'/servicePhilosophy'},{name:'产品介绍',path:'/products'},{name:'专业背景',path:'/academicBackground'},{name:'案例分享',path:'/caseShare'},{name:'研究动态',path:'/researchTrends'},{name:'能力模型',path:'/capabilityModel'}]
        //list:[{name:'首页',path:'/'},{name:'服务理念',path:'/servicePhilosophy'},{name:'产品介绍',path:'/products'},{name:'专业背景',path:'/academicBackground'},{name:'案例分享',path:'/caseShare'},{name:'研究动态',path:'/researchTrends'},{name:'能力模型',path:'/capabilityModel'},{name:'五育并举',path:'/'}]
      };
    },
    methods: {
      getActive(index,path){
        this.active=index;
        sessionStorage.setItem('active',this.active);
        //localStorage.setItem('active',this.active);
        this.$router.push(path);
      }
    }
}
</script>
<style lang="scss" scoped>
.navlist{
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 359px;
  font-size: 20px;
  color: #666;
  box-shadow: 0 3px 20px rgba(51,51,51,.2);
  background: #fff;
    letter-spacing:0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    min-width: 1200PX;
  div{
    width: 100px;
    margin-left: 47.4px;
    cursor: pointer;
    position: relative;
  }
  img{
    // width: 200px;
    height: 37px;
  }
  .active{
    color: #20A8D9;
    font-size: 22px;
    font-weight: bold;
  }
  .active:before{
    content: '';
    display: block;
    width: 40px;
    height: 3px;
    background: #20A8D9;
    bottom: -20px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
  }
}
</style>
