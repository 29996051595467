import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import api from './request/api'
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
Vue.prototype.$api = api;
Vue.config.productionTip = false
Vue.use(ElementUI);
// Vue.use(Viewer);
// Viewer.setDefaults({
//   Options: { 'inline': false, 'button': false, 'navbar': false, 'title': false, 'toolbar': false, 'tooltip': false, 'movable': false, 'zoomable': false, 'rotatable': false, 'scalable': false, 'transition': false, 'fullscreen': false, 'keyboard': false}
// })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
