<template>
  <div class="home">
       <div class="shade_home">
          <kinesis-container>
            <kinesis-element :strength="20" active :duration='1000' event='move' tag='div'>
              <img class="active22" src="../assets/img/main_bg2.png" alt="">
            </kinesis-element>
        </kinesis-container>  
        <div  class="box-active">
                 <div>
                    <div>
                    天才在线
                  </div>
                  <div>让素质育人成果清晰可见</div>
                 </div>
                 </div> 
       </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { KinesisContainer, KinesisElement } from 'vue-kinesis'
Vue.component('kinesis-container', KinesisContainer)
Vue.component('kinesis-element', KinesisElement)
export default {
  name: 'Home',
}
</script>
<style lang="scss" scoped>
.home_bg{
 width: 100%;
 position: relative;
}
.home{
  position: relative;
  background: url('../assets/img/main_bg.png') no-repeat;
  background-size: cover;
  height: calc(100% - 69px);
  overflow: hidden;
  .shade_home{
    background: rgba(77,120,147,.8);
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-top: 50px;
  }
  .active22{
    width: 700px;
  }
}
.box-active{
  position: absolute;
  top: 258px;
  left: 535px;
  font-size: 80px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 3px 6px #41647B;
}
</style>
