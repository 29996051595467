/**
 * authentication_api 账号API
 */


import axios from './http'; // 导入http中创建的axios实例

const common_api = {
  // 获取能力点
  menu(params) {
    return axios.get(`https://api.iouke.cn/ability_model_api/api/Standard/cocreation/menu`)
  },
  detail(params) {
    return axios.get(`https://api.iouke.cn/ability_model_api/api/Standard/cocreation/detail?id=${params.id}`)
  },
}

export default common_api;